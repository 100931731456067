import './FrequentlyQuestions.css';

import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { useEffect, useState } from 'react';

import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { Heading } from '../../../../../../components/Heading';
import FrequentlyQuestionApi from '../../../../../../api/FrequentlyQuestion';
import { Modal } from 'react-bootstrap';
import { useCourse } from '../../../../../../contexts/CourseContext';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';

function FrequentlyQuestions() {
  const { course } = useCourse();

  const [existingQuestions, setExistingQuestions] = useState([]);
  const [question, setQuestion] = useState('');
  const [questionAnswer, setQuestionAnswer] = useState('');

  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModal2, setIsShowModal2] = useState(false);

  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  const [questionEdit, setQuestionEdit] = useState('');
  const [questionAnswerEdit, setQuestionAnswerEdit] = useState('');
  const [questionId, setQuestionId] = useState('');

  useEffect(() => {
    if (course.id) {
      getQuestion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course.id]);

  async function getQuestion() {
    const questions = await FrequentlyQuestionApi.index(course.id);
    setExistingQuestions(questions);
  }

  async function deleteQuestion(event) {
    const { id, value } = event.target;
    if (value === 'Excluir') {
      await FrequentlyQuestionApi.delete(course.id, id);
      const newResponse = await FrequentlyQuestionApi.index(course.id);
      setExistingQuestions(newResponse);
      setMessage('Sua pergunta foi excluida com sucesso');
      setTypeMessage('sucesso');
      setIsShowModal2(true);
    }
  }

  async function updateQuestion() {
    if (
      questionEdit.length >= 6 &&
      questionEdit.length <= 255 &&
      questionAnswerEdit.length >= 6 &&
      questionAnswerEdit.length <= 255
    ) {
      await FrequentlyQuestionApi.update(course.id, questionId, questionEdit, questionAnswerEdit);
      const newResponse = await FrequentlyQuestionApi.index(course.id);
      setExistingQuestions(newResponse);
      setIsShowModal(false);
      setMessage('Sua pergunta foi atualizada com sucesso');
      setTypeMessage('sucesso');
      setIsShowModal2(true);
    } else {
      setMessage(
        'Você precisa informar campos válidos. Vale ressaltar que tanto campo de pergunta, quanto o campo de resposta suportam entre 6 e 255 caracteres'
      );
      setTypeMessage('erro');
      setIsShowModal2(true);
    }
  }

  function ListQuestions({ questions }) {
    return questions.map(({ id, question, questionAnswer }) => {
      return (
        <ul className="space-ul d-flex justify-content-between w-100  " key={id}>
          <div className="d-flex justify-content-between w-100">
            <div className="column big-text mb-2 mr-2">
              <p className="paragraph">{question}</p>
              <span>{questionAnswer}</span>
            </div>
            <div className="d-flex flex-nowrap">
              <input
                value="Editar"
                type="button"
                className="btn btn-sm  styleButton"
                id={id}
                onClick={() => {
                  setQuestionId(id);
                  setQuestionEdit(question);
                  setQuestionAnswerEdit(questionAnswer);
                  setIsShowModal(true);
                }}
              />
              <input
                value="Excluir"
                type="button"
                className="btn btn-outline-danger btn-sm styleButton"
                id={id}
                onClick={deleteQuestion}
              />
            </div>
          </div>
        </ul>
      );
    });
  }

  function handleChange(event) {
    const { name, value } = event.target;

    if (name === 'pergunta') {
      setQuestion(value);
    }
    if (name === 'resposta') {
      setQuestionAnswer(value);
    }

    if (name === 'questionEdit') {
      setQuestionEdit(value);
    }

    if (name === 'questionAnswerEdit') {
      setQuestionAnswerEdit(value);
    }
  }

  async function onSubmit(event) {
    event.preventDefault();

    try {
      if (
        question.length >= 6 &&
        question.length <= 255 &&
        questionAnswer.length >= 6 &&
        questionAnswer.length <= 255
      ) {
        await FrequentlyQuestionApi.store(course.id, question, questionAnswer);
        const newResponse = await FrequentlyQuestionApi.index(course.id);
        await setExistingQuestions(newResponse);
        setQuestion('');
        setQuestionAnswer('');
        setMessage('Sua pergunta foi cadastrada com sucesso');
        setTypeMessage('sucesso');
        setIsShowModal2(true);
      } else {
        setMessage(
          'Você precisa informar campos válidos. Vale ressaltar que tanto campo de pergunta, quanto o campo de resposta suportam entre 6 e 255 caracteres'
        );
        setTypeMessage('erro');
        setIsShowModal2(true);
      }
    } catch (error) {
      setMessage(ErrorResponse(error));
    }
  }

  return (
    <Box width="full">
      <Card width="full" as="form" onSubmit={onSubmit}>
        <CardHeader>
          <Heading as="h4" fontSize="2xl">
            Perguntas frequentes
          </Heading>
        </CardHeader>

        <CardBody width="full" display="flex" flexDirection="column" gap={2}>
          <FormControl>
            <FormLabel> Digite a sua pergunta</FormLabel>
            <Input
              type="text"
              name="pergunta"
              fontSize="sm"
              size="sm"
              focusBorderColor="default.500"
              borderRadius="6px"
              placeholder="Ex: Não estou tendo acesso aos meus cursos"
              value={question}
              onChange={handleChange}
              minLength="6"
              maxLength="350"
            />
          </FormControl>

          <FormControl>
            <FormLabel> Digite a sua pergunta</FormLabel>
            <Textarea
              onChange={handleChange}
              value={questionAnswer}
              name="resposta"
              fontSize="sm"
              size="sm"
              borderRadius="6px"
              id=""
              cols="25"
              rows="10"
              focusBorderColor="default.500"
              placeholder="Ex: basta clicar no menu de acesso aos clientes"
              minLength="6"
              maxLength="350"
            />
          </FormControl>

          <Box width="full" display="flex" justifyContent="flex-end" py={2}>
            <Button type="submit" size="sm" colorScheme="default">
              Salvar
            </Button>
          </Box>
        </CardBody>

        <CardFooter width="full" justifyContent="flex-end">
          {existingQuestions && <hr />}

          {existingQuestions && <ListQuestions questions={existingQuestions} />}
        </CardFooter>
      </Card>

      <Modal
        centered
        size="lg"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="row d-flex justify-content-center mb-1 py-4">
            <label
              htmlFor="pergunta"
              className="font-weight-bold text-title-label mb-1"
              style={{ fontSize: '16px' }}
            >
              Pergunta
            </label>
            <input
              name="questionEdit"
              type="text"
              value={questionEdit}
              className="fs-14px form-control"
              onChange={handleChange}
            />
            <label
              htmlFor="pergunta"
              className="font-weight-bold text-title-label mt-4 mb-1"
              style={{ fontSize: '16px' }}
            >
              Resposta
            </label>
            <textarea
              name="questionAnswerEdit"
              type="text"
              value={questionAnswerEdit}
              className="fs-14px form-control "
              onChange={handleChange}
              rows="10"
            />
          </div>
          <div className="row d-flex justify-content-end mb-1 ">
            <button
              onClick={() => setIsShowModal(false)}
              className="btn btn-danger "
              style={{
                color: 'white',
              }}
            >
              Descartar alterações
            </button>
            <button
              onClick={updateQuestion}
              className="btn btn-light ml-2"
              style={{
                backgroundColor: '#EB7129',
                color: 'white',
              }}
            >
              Confirmar edição
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        size="md"
        show={isShowModal2}
        onHide={() => setIsShowModal2(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="row d-flex justify-content-center mb-1 py-4">
            {typeMessage === 'sucesso' && (
              <FaCheckCircle
                className="d-flex justify-content-center mb-2"
                style={{ color: 'green', height: '60px', width: '60px' }}
              />
            )}
            {typeMessage === 'erro' && (
              <FaExclamationCircle
                className="d-flex justify-content-center mb-2"
                style={{
                  color: 'red',
                  height: '60px',
                  width: '60px',
                }}
              />
            )}
            <h3 className="text-justify">{message}</h3>
          </div>
          <div className="d-flex justify-content-center mb-4 ">
            <button
              onClick={() => setIsShowModal2(false)}
              className="btn btn-light"
              style={{
                backgroundColor: '#EB7129',
                color: 'white',
                alignItems: 'center',
              }}
            >
              Retornar para a tela de perguntas
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </Box>
  );
}

export default FrequentlyQuestions;
