import Joi from 'joi';
import { Recurrence } from '../../types';
import { RecurrencePlanPaymentForm } from './types';

const recurrence: Recurrence[] = [
  'daily',
  'biweekly',
  'monthly',
  'bimonthly',
  'quarterly',
  'semiannual',
  'yearly',
];

const status = ['ATIVO', 'INATIVO', 'NAO_LISTADO', 'EM_EDICAO'];

const REQUIRED_MESSAGE = 'Você deve escolher pelo menos uma opção.';

export const RecurrencePlanPaymentFormSchemaValidator =
  Joi.object<RecurrencePlanPaymentForm>().keys({
    name: Joi.string().required().messages({
      'string.empty': 'Nome não pode ficar vazio',
    }),
    coursesIds: Joi.array().items(Joi.number().integer().positive()).required().min(1).messages({
      'array.min': 'Voce deve selecionar pelo menos um produto',
    }),
    value: Joi.number().positive().required().messages({
      'number.base': 'Valor deve ser um número.',
      'number.integer': 'Valor deve ser um número inteiro.',
      'number.positive': 'Valor deve ser um número positivo.',
      'any.required': 'Valor é obrigatório.',
    }),
    paymentMethod: Joi.array().items(Joi.string().valid('credit_card')).required().min(1).messages({
      'array.min': REQUIRED_MESSAGE,
    }),
    hasTestPeriod: Joi.boolean().default(false),
    trialDays: Joi.when('hasTestPeriod', {
      is: true,
      then: Joi.number().integer().required().messages({
        'number.base': 'Deve ser um número.',
        'number.integer': 'Deve ser um número inteiro.',
        'number.positive': 'Deve ser um número positivo.',
        'any.required': 'É obrigatório.',
      }),
      otherwise: Joi.number().integer().default(0),
    }),
    recurrence: Joi.string()
      .valid(...recurrence)
      .required()
      .messages({ 'string.empty': 'Selecione uma recorrência' }),
    planDuration: Joi.string()
      .valid('ATE_O_CANCELAMENTO', 'NUMERO_MAXIMO_DE_PAGAMENTOS')
      .required()
      .messages({ 'string.empty': 'Escolha a duração do plano' }),
    maxPayments: Joi.when('planDuration', {
      is: 'NUMERO_MAXIMO_DE_PAGAMENTOS',
      then: Joi.number().positive().required().messages({
        'number.base': 'Deve ser um número.',
        'number.integer': 'Deve ser um número inteiro.',
        'number.positive': 'Deve ser um número positivo.',
        'any.required': 'Defina o número máximo de pagamentos.',
      }),
      otherwise: Joi.string().allow(null).only().required().optional(),
    }),
    losingAccessAfterLastPayment: Joi.when('planDuration', {
      is: 'NUMERO_MAXIMO_DE_PAGAMENTOS',
      then: Joi.boolean().default(false).required().messages({
        'any.required': REQUIRED_MESSAGE,
      }),
      otherwise: Joi.boolean().default(false),
    }),
    status: Joi.string()
      .valid(...status)
      .required()
      .messages({ 'string.empty': 'Selecione um status' }),
  });
