import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useFetch from '../../../../../../hooks/useFetch';
import useHandleChange from '../../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../../../utils/scrollToInvalidInput';
import { Course, PlanResponse } from '../types';
import { RecurrencePlanPaymentFormSchemaValidator } from './schemaValidator';
import { RecurrencePlanPaymentForm } from './types';

const INITIAL_STATE = {
  paymentMethod: ['credit_card'],
  hasTestPeriod: false,
  recurrence: 'monthly',
  status: 'ATIVO',
  planDuration: 'ATE_O_CANCELAMENTO',
  name: '',
  coursesIds: [],
  value: null,
  maxPayments: null,
  trialDays: null,
  losingAccessAfterLastPayment: false,
} as RecurrencePlanPaymentForm;

interface SelectedCourse {
  label: string;
  value: number;
}

export interface UsePlanManagementEditPaymentProps {
  planData: PlanResponse;
  onIsLoadingChange: (isLoading: boolean) => void;
}

export default function usePlanManagementEditPayment({
  planData,
  onIsLoadingChange,
}: UsePlanManagementEditPaymentProps) {
  const [selectedCourseList, setSelectedCourseList] = useState<SelectedCourse[]>([]);

  const { planId } = useParams<{ planId: string }>();
  const { push } = useHistory();

  useEffect(() => {
    setSelectedCourseList(
      planData?.courses.map(({ id, name }) => ({ value: id, label: name })) ?? []
    );
  }, [planData?.courses]);

  const { data: response, loading: isLoading } = useFetch<UnificadaFront.ResponseJSON<Course[]>>({
    method: 'get',
    url: '/recurrence-plan/list-course',
    authenticated: true,
    autoFetch: true,
  });

  const courseListOptions = response?.data?.map(course => ({
    value: course.id,
    label: course.name,
  }));

  useEffect(() => {
    onIsLoadingChange(isLoading);
  }, [isLoading, onIsLoadingChange]);

  const isUpdating = !!planId && !!planData;

  const {
    form,
    setForm,
    handleChange,
    handleCancel,
    onChanged: hasChange,
    setOnChanged: setHasChange,
  } = useHandleChange<RecurrencePlanPaymentForm>(
    INITIAL_STATE,
    isUpdating && {
      paymentMethod: planData?.paymentMethod,
      hasTestPeriod: planData?.hasTestPeriod,
      recurrence: planData?.recurrence,
      status: planData?.status,
      planDuration: planData?.planDuration,
      name: planData?.name,
      coursesIds: planData?.courses.map(({ id }) => id),
      value: Number(planData?.value),
      maxPayments: planData?.maxPayments,
      trialDays: planData?.trialDays,
      losingAccessAfterLastPayment: planData?.losingAccessAfterLastPayment,
    }
  );

  function handlePlanValueChange(planValue: number) {
    setForm(prevForm => ({
      ...prevForm,
      value: planValue,
    }));

    setHasChange(true);
  }

  function handlePlanDurationRadioChange(value: string) {
    setForm(prevForm => ({
      ...prevForm,
      planDuration: value as RecurrencePlanPaymentForm['planDuration'],
      maxPayments: value === 'ATE_O_CANCELAMENTO' ? null : prevForm.maxPayments,
    }));

    setHasChange(true);
  }

  function handleStatusRadioChange(value: string) {
    setForm(prevForm => ({ ...prevForm, status: value as RecurrencePlanPaymentForm['status'] }));

    setHasChange(true);
  }

  function handleCourseListChange(newValue: SelectedCourse[]) {
    setSelectedCourseList(newValue);

    setForm(prevForm => ({ ...prevForm, coursesIds: newValue.map(({ value }) => value) }));

    setHasChange(true);
  }

  function handleLosingAccessAfterLastPaymentChange(event: ChangeEvent<HTMLInputElement>) {
    setForm(prevForm => ({
      ...prevForm,
      losingAccessAfterLastPayment: event.target.checked,
    }));

    setHasChange(true);
  }

  function handleCancelButtonClick() {
    if (!hasChange) {
      return push('/recurrence/plan-management');
    }

    handleCancel();
  }

  const payload = {
    ...form,
    trialDays: 0,
  };

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: payload,
    url: isUpdating ? `/recurrence-plan/${planId}/payment` : '/recurrence-plan',
    method: isUpdating ? 'patch' : 'post',
    authenticated: true,
    schemaValidator: RecurrencePlanPaymentFormSchemaValidator,
    removeNullProps: false,
    onSuccess: {
      callback(response: any) {
        const id = isUpdating ? planId : response.data.data.recurrencePlanId;

        push(`/recurrence/plan-management/edit/${id}/description`);
      },
    },
  });

  const parsedValidation = formValidation as any;

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    scrollToInvalidInput();

    await handleSubmit();
  }

  return {
    form,
    isSubmitting,
    formValidation: parsedValidation,
    hasChange,
    courseListOptions,
    selectedCourseList,
    isUpdating,
    handleCourseListChange,
    handleChange,
    handlePlanValueChange,
    handlePlanDurationRadioChange,
    handleStatusRadioChange,
    handleLosingAccessAfterLastPaymentChange,
    handleCancelButtonClick,
    onSubmit,
  };
}
