import { AspectRatio, Box, Heading, Stack, Text, keyframes } from '@chakra-ui/react';
import VimeoPlayer from '@vimeo/player';
import { useCallback, useEffect, useState } from 'react';
import { FiAlertTriangle as Alert } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { IParams, IVideoStatisticsResponse } from '../types';
import { isBetween, targets } from '../utils';
import { useCourseStudent } from '../../../../../contexts/CourseStudentContextV2';
import useFetch from '../../../../../hooks/useFetch';
import Toast from '../../../../../components/Toast';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { ContentUserAPI } from '../../../../../api/ContentUser';
import { PagingContents } from '../../../components/PagingContents';
import FileDownload from '../../../../../components/FileDownload';

type VideoType = 'vimeo' | 'panda';

const ALERT = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

function ViewVideo() {
  const { courseId, contentId } = useParams<IParams>();

  const { highlightColor, currentContent: content } = useCourseStudent();

  const [lastRange, setLastRange] = useState(0);
  const [videoPlayer, setVideoPlayer] = useState(null);
  const [iframe, setIframe] = useState(null);
  const [duration, setDuration] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);
  const [iframeId, setIframeId] = useState<string | null>(null);
  const [typeVideo, setTypeVideo] = useState<VideoType | null>(null);

  useEffect(() => {
    if (content?.link) {
      const pandaRegex =
        /https:\/\/player-vz-([a-f0-9-]+)\.tv\.pandavideo\.com\.br\/embed\/\?v=([a-f0-9-]+)/;

      const matches = content?.link.match(pandaRegex);

      if (matches) {
        setIframeId(`panda-${matches[2]}`);
        setTypeVideo('panda');
      } else {
        setIframeId(`iframe${contentId}`);
        setTypeVideo('vimeo');
      }
    }
  }, [content?.link, contentId]);

  useEffect(() => {
    if (content && iframe && typeVideo === 'vimeo') {
      setIframe(document.getElementById(`iframe${contentId}`));

      setVideoPlayer(new VimeoPlayer(iframe));
    }
  }, [content, contentId, iframe, typeVideo]);

  const { data: response, fetchData } = useFetch<
    UnificadaFront.ResponseJSON<IVideoStatisticsResponse>
  >({
    url: `/contents/${contentId}/content/${courseId}/course/video-statistics`,
    method: 'get',
    autoFetch: false,
    authenticated: true,
  });

  const videoStatistics = response?.data;

  useEffect(() => {
    if (contentId && courseId) {
      fetchData();
    }
  }, [contentId, courseId, fetchData]);

  useEffect(() => {
    if (videoStatistics && videoPlayer) {
      setIsBlocked(videoStatistics?.blocked);

      if (duration) {
        const currentTime = Math.round(duration * (videoStatistics?.progress / 100));

        videoPlayer.setCurrentTime(currentTime).catch(function (error: any) {
          Toast(ErrorResponse(error), 'error');
        });
      }
    }
  }, [duration, videoPlayer, videoStatistics]);

  useEffect(() => {
    if (videoPlayer) {
      videoPlayer
        .getDuration()
        .then(function (seconds: any) {
          setDuration(seconds);
        })
        .catch(function (error: any) {
          Toast(ErrorResponse(error), 'error');
        });
    }
  }, [contentId, courseId, videoPlayer]);

  const sendVideoStatistics = useCallback(
    async data => {
      const { percent } = data;
      const progress = Math.ceil(percent * 100);

      if (progress === 100 && isBlocked) {
        setIsBlocked(false);
      }

      for (let index = 0; index < targets.length; index++) {
        const start = index > 0 ? targets[index - 1] : 0;
        const end = targets[index];

        if (isBetween(start, end, progress) && lastRange !== end) {
          setLastRange(targets[index]);
          break;
        }
      }
    },
    [isBlocked, lastRange]
  );

  useEffect(() => {
    if (content && iframe && videoPlayer) {
      videoPlayer.on('timeupdate', sendVideoStatistics);
    }
  }, [content, iframe, sendVideoStatistics, videoPlayer]);

  useEffect(() => {
    async function sendProgress() {
      const response = await ContentUserAPI.sendStatistics(
        contentId,
        parseInt(courseId),
        lastRange
      );

      setIsBlocked(response.blocked);
    }

    if (lastRange > 0) {
      sendProgress();
    }
  }, [contentId, courseId, lastRange]);

  return (
    <Box>
      <Heading
        color="#FFF"
        fontSize={{ base: '18px', md: '24px', lg: '24px' }}
        fontWeight="500"
        paddingBottom={{ base: '9px', lg: '16px' }}
      >
        {content?.title}
      </Heading>

      <Stack spacing={{ base: '9px', lg: '16px' }} marginTop={{ base: '-5', lg: '-6' }}>
        <AspectRatio maxW="full" ratio={16 / 9}>
          {!isBlocked ? (
            <iframe id={iframeId} title="title" src={content?.link} allowFullScreen />
          ) : (
            <Box
              position="absolute"
              top="16"
              right="16"
              bottom="16"
              left="16"
              bg="#00000033"
              zIndex="9"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box py="16" px="32" bg="#ffffff">
                <Box
                  animation={`${ALERT} 1.1s ease-out alternate infinite running`}
                  display="flex"
                  alignItems="center"
                >
                  <Alert color="#ef233c" size={32} />
                  <Text ml={4} fontSize="2xl" lineHeight="base" color="dark.500">
                    Seu acesso foi limitado
                  </Text>
                </Box>
              </Box>
            </Box>
          )}
        </AspectRatio>
      </Stack>

      <Box marginTop={{ base: '10px', lg: '29px' }}>
        <PagingContents />
      </Box>

      {content?.download && (
        <Box marginY={{ base: '22px', lg: '30px' }}>
          <FileDownload name={content?.download} highlightColor={highlightColor} />
        </Box>
      )}

      {content?.content && (
        <Box
          color="#FFF"
          fontSize={{ base: '14px', md: '18px', lg: '18px' }}
          dangerouslySetInnerHTML={{ __html: content?.content }}
          className="ql-editor"
          sx={{
            iframe: {
              width: '100%',
              aspectRatio: '16/9',
            },
            a: { color: '#06c', textDecoration: 'underline' },
            blockquote: { borderLeft: '5px solid #ccc', paddingLeft: '30px' }, // Estilo para citação
          }}
        />
      )}
    </Box>
  );
}

export default ViewVideo;
