import { Flex, useColorMode } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import Questions from '../DashboardStudent/Questions';
import NetflixMemberAreaFooter from './components/Footer';
import FullPageLoading from './components/FullPageLoading';
import Header from './components/Header';
import Home from './components/Home';
import PreviewHeader from './components/PreviewHeader';
import SimpleImageSlider from './components/SimpleImageSlider';
import VideoBanner from './components/VideoBanner';
import ContentsView from './pages/ContentsView';
import Modules from './pages/Modules';
import Payments from './pages/Payments';
import Profile from './pages/Profile';
import { Banner, Course, Showcase } from './types';

interface ShowcaseResponse {
  covers: Banner[];
  showcases: Showcase[];
}

export default function NetflixMemberArea() {
  const { search } = useLocation();
  const { setColorMode } = useColorMode();

  const urlSearchParams = new URLSearchParams(search);
  const isPreview = urlSearchParams.get('preview') === 'true';
  const showCasesUrl = isPreview ? '/showcase/members-area-netflix-preview' : '/showcase/student';

  // Define o tema claro ao desmontar o componente e ao recarregar a página
  useEffect(() => {
    setColorMode('dark');

    const handleBeforeUnload = () => setColorMode('light');

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      setColorMode('light');
    };
  }, [setColorMode]);

  const { data: showcaseResponse, loading: isFetchingShowcase } = useFetch<
    UnificadaFront.ResponseJSON<ShowcaseResponse>
  >({
    method: 'get',
    url: showCasesUrl,
    autoFetch: true,
    authenticated: true,
  });

  const { data: myProductsResponse, loading: isFetchingMyProducts } = useFetch<
    UnificadaFront.ResponseJSON<Course[]>
  >({
    method: 'get',
    url: '/students/courses',
    autoFetch: true,
    authenticated: true,
  });

  const banners = showcaseResponse?.data?.covers;
  const hasBanner = !!banners?.length;
  const myProducts = myProductsResponse?.data ?? [];
  const hasProducts = !!myProducts?.length;
  const showcases = showcaseResponse?.data?.showcases;
  const firstBanner = banners?.[0];
  const bannerType = firstBanner?.bannerType;
  const isLoading = isFetchingShowcase || isFetchingMyProducts;
  const videoBannerLink = banners?.[0]?.videoLink;

  if (isLoading) return <FullPageLoading />;

  const showcaseProducts =
    showcases
      ?.map(showcase =>
        showcase.showcaseCourse.map(showcaseCourseItem => showcaseCourseItem.course)
      )
      ?.flat() ?? [];

  const allProducts = [...showcaseProducts];

  if (myProducts && myProducts.length > 0) {
    allProducts.push(...myProducts);
  }

  return (
    <Flex
      id="netflix-member-area-container"
      height="100vh"
      direction="column"
      justify="space-between"
      bgColor="#202123"
      overflowY="auto"
      overflowX="hidden"
      sx={{ '::-webkit-scrollbar': { width: '0', background: 'transparent' } }}
    >
      {isPreview ? <PreviewHeader /> : <Header />}

      <Flex flex={1} direction="column">
        <Switch>
          <>
            <Route
              exact
              path={['/members-area/v2', '/members-area/v2/preview']}
              component={() => (
                <>
                  {bannerType === 'video' && <VideoBanner link={videoBannerLink} />}
                  {bannerType === 'simple-image' && <SimpleImageSlider banners={banners} />}

                  <Home
                    hasProducts={hasProducts}
                    myProducts={myProducts}
                    showcases={showcases}
                    banners={banners}
                    isPreview={isPreview}
                  />
                </>
              )}
            />

            <Route
              path="/members-area/v2/course/:courseId/modules*"
              component={() => (
                <>
                  {bannerType === 'video' && <VideoBanner link={videoBannerLink} />}
                  {bannerType === 'simple-image' && <SimpleImageSlider banners={banners} />}

                  <Modules hasBanner={hasBanner} products={allProducts} />
                </>
              )}
            />

            <Route
              path="/members-area/v2/course/:courseId/module/:moduleId*"
              component={ContentsView}
            />

            <Route path="/members-area/v2/payments" component={Payments} />
            <Route path="/members-area/v2/questions" component={Questions} />
            <Route path="/members-area/v2/profile/user" component={Profile} />
          </>
        </Switch>

        <NetflixMemberAreaFooter />
      </Flex>
    </Flex>
  );
}
